// // import Mock from 'mockjs'
// export default {
//   getMenu: config => {
//     const { username, password } = JSON.parse(config.body)
//     // 先判断用户是否存在
//     // 判断账号和密码是否对应
//     if (username === 'admin' && password === 'liberlive@2023') {
//       return {
//         code: 20000,
//         data: {
//           menu: [
//             {
//               path: '/home',
//               name: 'home',
//               label: '首页',
//               icon: 's-data',
//               url: 'Home.vue',
//               show: '1'
//             },

//             {
//               path: '/user',
//               name: 'user',
//               label: '用户管理',
//               icon: 'user',
//               url: 'User.vue',
//               show: '1'
//             },
//             {
//               path: '/userDetail',
//               name: 'userDetail',
//               label: '用户详情',
//               icon: 'user',
//               url: 'UserDetail.vue',
//               show: '0'
//             },
//             {
//               label: '曲谱',
//               icon: 'location',
//               show: '1',
//               children: [
//                 {
//                   path: '/song',
//                   name: 'song',
//                   label: '曲谱管理',
//                   icon: 'service',
//                   url: 'Song.vue',
//                   show: '1'
//                 },
//                 {
//                   path: '/songCategoryEdit',
//                   name: 'songCategoryEdit',
//                   label: '曲谱分类',
//                   icon: 'service',
//                   url: 'SongCategoryEdit.vue',
//                   show: '1'
//                 },
//                 {
//                   path: '/songError',
//                   name: 'songError',
//                   label: '曲谱报错',
//                   icon: 'service',
//                   url: 'SongError.vue',
//                   show: '1'
//                 },
//                 {
//                   path: '/songEdit',
//                   name: 'songEdit',
//                   label: '曲谱编辑',
//                   icon: 'service',
//                   url: 'SongEdit.vue',
//                   show: '0'
//                 },
//               ]
//             },
//             {
//               path: '/pick',
//               name: 'pick',
//               label: '拨片管理',
//               icon: 'service',
//               url: 'Pick.vue',
//               show: '1'
//             },
//             {
//               path: '/drum',
//               name: 'drum',
//               label: '鼓机管理',
//               icon: 'service',
//               url: 'Drum.vue',
//               show: '1'
//             },
//             {
//               path: '/tutorial',
//               name: 'tutorial',
//               label: '教学视频',
//               icon: 'service',
//               url: 'Tutorial.vue',
//               show: '1'
//             },
//             {
//               path: '/errorLog',
//               name: 'errorLog',
//               label: '错误日志',
//               icon: 'service',
//               url: 'ErrorLog.vue',
//               show: '1'
//             },
//             {
//               path: '/whiteBlack',
//               name: 'whiteBlack',
//               label: '白名单',
//               icon: 'service',
//               url: 'WhiteBlack.vue',
//               show: '1'
//             },
//             {
//               path: '/versionApp',
//               name: 'versionApp',
//               label: 'App版本',
//               icon: 'service',
//               url: 'VersionApp.vue',
//               show: '1'
//             },
//             {
//               path: '/versionDevice',
//               name: 'versionDevice',
//               label: '固件版本',
//               icon: 'service',
//               url: 'VersionDevice.vue',
//               show: '1'
//             },
//           ],
//           token: Mock.Random.guid(),
//           root: true,
//           message: '获取成功'
//         }
//       }
//     } else if ((username === 'wutangxuebi' && password === 'wutangxuebi')||
//     (username === 'xiaomaiguozhi' && password === 'xiaomaiguozhi')||
//     (username === 'maotai' && password === 'maotai')) {
//       return {
//         code: 20000,
//         data: {
//           menu: [
//             {
//               label: '曲谱',
//               icon: 'location',
//               show: '1',
//               children: [
//                 {
//                   path: '/song',
//                   name: 'song',
//                   label: '曲谱管理',
//                   icon: 'service',
//                   url: 'Song.vue',
//                   show: '1'
//                 },
//               ]
//             },
//             {
//               path: '/songEdit',
//               name: 'songEdit',
//               label: '曲谱编辑',
//               icon: 'service',
//               url: 'SongEdit.vue',
//               show: '0'
//             },
//           ],
//           token: Mock.Random.guid(),
//           root: false,
//           message: '获取成功'
//         }
//       }
//     } else {
//       return {
//         code: -999,
//         data: {
//           message: '密码错误'
//         }
//       }
//     }

//   }
// }
