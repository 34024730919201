var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "manage" }, [
    _c(
      "div",
      { staticClass: "manage-header" },
      [
        _c(
          "el-form",
          { attrs: { inline: true, model: _vm.searchForm } },
          [
            _c(
              "el-form-item",
              [
                _c("el-input", {
                  attrs: { placeholder: "用户id", clearable: "" },
                  model: {
                    value: _vm.searchForm.userId,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchForm, "userId", $$v)
                    },
                    expression: "searchForm.userId",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c("el-input", {
                  attrs: { placeholder: "用户昵称", clearable: "" },
                  model: {
                    value: _vm.searchForm.nickName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchForm, "nickName", $$v)
                    },
                    expression: "searchForm.nickName",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c("el-input", {
                  attrs: { placeholder: "用户手机号", clearable: "" },
                  model: {
                    value: _vm.searchForm.mobile,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchForm, "mobile", $$v)
                    },
                    expression: "searchForm.mobile",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c("el-input", {
                  attrs: { placeholder: "设备id", clearable: "" },
                  model: {
                    value: _vm.searchForm.deviceId,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchForm, "deviceId", $$v)
                    },
                    expression: "searchForm.deviceId",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c("el-input", {
                  attrs: { placeholder: "第三方openId", clearable: "" },
                  model: {
                    value: _vm.searchForm.externalOpenId,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchForm, "externalOpenId", $$v)
                    },
                    expression: "searchForm.externalOpenId",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c("el-input", {
                  attrs: { placeholder: "第三方unionId", clearable: "" },
                  model: {
                    value: _vm.searchForm.externalUnionId,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchForm, "externalUnionId", $$v)
                    },
                    expression: "searchForm.externalUnionId",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.search } },
                  [_vm._v("查询")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "common-tabel" },
      [
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: { height: "90%", data: _vm.tableData },
          },
          [
            _c("el-table-column", {
              attrs: { prop: "userId", label: "用户id" },
            }),
            _c("el-table-column", {
              attrs: { prop: "clientId", label: "渠道" },
            }),
            _c("el-table-column", {
              attrs: { prop: "nickName", label: "昵称" },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "mobile",
                label: "手机",
                formatter: _vm.formatMobile,
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "sex", label: "性别" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-tag",
                        {
                          attrs: {
                            type: _vm.getSexStatusTagType(scope.row.sex),
                          },
                        },
                        [_vm._v(_vm._s(_vm.getSexStatusText(scope.row.sex)))]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "ipAddress", label: "ip地址" },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "createTime",
                label: "创建时间",
                formatter: _vm.formatDate,
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "status", label: "状态" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-tag",
                        {
                          attrs: {
                            type: _vm.getStatusTagType(scope.row.status),
                          },
                        },
                        [_vm._v(_vm._s(_vm.getStatusText(scope.row.status)))]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "addr", label: "操作" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "success" },
                          on: {
                            click: function ($event) {
                              return _vm.handleDetail(scope.row)
                            },
                          },
                        },
                        [_vm._v("详细")]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "pager" },
          [
            _c("el-pagination", {
              attrs: { layout: "prev, pager, next", total: this.total },
              on: { "current-change": _vm.handlePage },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }